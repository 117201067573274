import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";

const defaultSafeAreaInsets = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

interface NativeAppDataType {
  isNativeApp?: boolean;
  appVersion?: string;
  safeAreaInsets: typeof defaultSafeAreaInsets;
}

declare global {
  interface Window {
    isNativeApp?: boolean;
    appVersion?: string;
    safeAreaInsets: typeof defaultSafeAreaInsets;
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
  }
}

export const NativeAppContext = createContext<NativeAppDataType>({
  isNativeApp: false,
  appVersion: "",
  safeAreaInsets: defaultSafeAreaInsets,
});

export function NativeAppProvider({ children }: { children: React.ReactNode }) {
  const [nativeAppData, setNativeAppData] = useState<NativeAppDataType>({
    isNativeApp: false,
    appVersion: "",
    safeAreaInsets: defaultSafeAreaInsets,
  });
  const navigate = useNavigate();

  // Initialize native app data
  useEffect(() => {
    const isBrowser = typeof window !== "undefined";
    const isNativeApp = isBrowser && window.isNativeApp === true;

    if (!isNativeApp) {
      return;
    }

    setNativeAppData({
      isNativeApp: true,
      appVersion: window.appVersion ?? "",
      safeAreaInsets: window.safeAreaInsets,
    });

    // Fix PhotoSwipe safe area insets
    const style = document.createElement("style");
    style.id = "fix-safe-area-insets";
    style.innerHTML = `
/* PhotoSwipe Fix */
.pswp__top-bar {
  top: ${window.safeAreaInsets.top}px !important;
}
    `;
    document.head.appendChild(style);

    // Handle react native events
    const handleMessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      console.log("Data received from React Native:", data);

      if (data.type === "replace") {
        navigate(data.url, { replace: true });
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      const style = document.getElementById("fix-safe-area-insets");
      if (style) {
        style.remove();
      }

      window.removeEventListener("message", handleMessage);
    };
  }, [navigate]);

  const contextValue = useMemo(() => nativeAppData, [nativeAppData]);

  return (
    <NativeAppContext.Provider value={contextValue}>
      {children}
    </NativeAppContext.Provider>
  );
}

export function useNativeApp() {
  return useContext(NativeAppContext);
}

export function useAppFocus() {
  // Handle app active/inactive events
  const [focused, setFocused] = useState(true);
  useEffect(() => {
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    document.addEventListener("app-active", onFocus);
    document.addEventListener("app-in-active", onBlur);

    return () => {
      document.removeEventListener("app-active", onFocus);
      document.removeEventListener("app-in-active", onBlur);
    };
  }, []);

  // Handle app reload on focus after 30 minutes
  const [lastFocusedTime, setLastFocusedTime] = useState(Date.now());
  useEffect(() => {
    if (focused && Date.now() - lastFocusedTime > 30 * 60 * 1000) {
      // TODO: Handle safe area insets
      // Reload the app
      // window.location.reload();
    }

    setLastFocusedTime(Date.now());
  }, [focused, lastFocusedTime]);

  return focused;
}

export function useHandleActivityOpen() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleOpenActivity = () => {
      const isShowHomeTabNavbar = !!document.getElementById("home-nav-tabbar");

      if (isShowHomeTabNavbar) {
        navigate("/my/activity", { replace: true });
      } else {
        navigate("/my/activity");
      }
    };

    document.addEventListener("open-activity", handleOpenActivity);

    return () => {
      document.removeEventListener("open-activity", handleOpenActivity);
    };
  }, [navigate]);
}

export function sendNativeAppMessage(data: unknown) {
  if (window.ReactNativeWebView) {
    const message = JSON.stringify(data);
    window.ReactNativeWebView.postMessage(message);
    return true;
  }
  return false;
}
